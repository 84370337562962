.visible {
  visibility: visible;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
}

.inset-0 {
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.top-0 {
  top: 0px;
}

.top-1 {
  top: 0.25rem;
}

.top-1\.5 {
  top: 0.375rem;
}

.-top-3 {
  top: -0.75rem;
}

.-top-8 {
  top: -2rem;
}

.bottom-0 {
  bottom: 0px;
}

.left-2\/4 {
  left: 50%;
}

.z-0 {
  z-index: 0;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.float-left {
  float: left;
}

.m-0 {
  margin: 0px;
}

.mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.mx-8 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.-mx-4 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.my-0 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.my-3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.my-3\.5 {
  margin-top: 0.875rem;
  margin-bottom: 0.875rem;
}

.mt-0 {
  margin-top: 0px;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-3 {
  margin-top: 0.75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mt-24 {
  margin-top: 6rem;
}

.mt-2\.5 {
  margin-top: 0.625rem;
}

.-mt-50 {
  margin-top: -50px;
}

.mr-0 {
  margin-right: 0px;
}

.mr-1 {
  margin-right: 0.25rem;
}

.mr-3 {
  margin-right: 0.75rem;
}

.mr-6 {
  margin-right: 1.5rem;
}

.mr-14 {
  margin-right: 3.5rem;
}

.mr-16 {
  margin-right: 4rem;
}

.mr-1\.5 {
  margin-right: 0.375rem;
}

.-mr-4 {
  margin-right: -1rem;
}

.mb-0 {
  margin-bottom: 0px;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-14 {
  margin-bottom: 3.5rem;
}

.ml-1 {
  margin-left: 0.25rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-auto {
  margin-left: auto;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-4 {
  height: 1rem;
}

.h-6 {
  height: 1.5rem;
}

.h-8 {
  height: 2rem;
}

.h-9 {
  height: 2.25rem;
}

.h-12 {
  height: 3rem;
}

.h-14 {
  height: 3.5rem;
}

.h-24 {
  height: 6rem;
}

.h-96 {
  height: 24rem;
}

.h-full {
  height: 100%;
}

.h-graph {
  height: calc(100vh - 80px);
}

.max-h-full {
  max-height: 100%;
}

.w-6 {
  width: 1.5rem;
}

.w-8 {
  width: 2rem;
}

.w-12 {
  width: 3rem;
}

.w-14 {
  width: 3.5rem;
}

.w-24 {
  width: 6rem;
}

.w-60 {
  width: 15rem;
}

.w-200 {
  width: 200px;
}

.w-312 {
  width: 312px;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/3 {
  width: 33.333333%;
}

.w-6\/12 {
  width: 50%;
}

.w-full {
  width: 100%;
}

.w-min {
  width: -webkit-min-content;
  width: min-content;
}

.max-w-4xl {
  max-width: 56rem;
}

.flex-1 {
  flex: 1 1 0%;
}

.flex-grow {
  flex-grow: 1;
}

.transform {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  -webkit-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:scale-110:hover {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes ping {
  75%, 100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0;
  }
}

@keyframes ping {
  75%, 100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0;
  }
}

@-webkit-keyframes pulse {
  50% {
    opacity: .5;
  }
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

@-webkit-keyframes bounce {
  0%, 100% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1);
  }

  50% {
    -webkit-transform: none;
            transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
}

@keyframes bounce {
  0%, 100% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1);
  }

  50% {
    -webkit-transform: none;
            transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
}

.cursor-pointer {
  cursor: pointer;
}

.resize {
  resize: both;
}

.grid-flow-row {
  grid-auto-flow: row;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-center {
  align-items: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-8 {
  gap: 2rem;
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rounded-sm {
  border-radius: 0.125rem;
}

.rounded {
  border-radius: 0.25rem;
}

.rounded-md {
  border-radius: 0.375rem;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-t {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.rounded-b {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.rounded-bl {
  border-bottom-left-radius: 0.25rem;
}

.first\:rounded-tl:first-child {
  border-top-left-radius: 0.25rem;
}

.first\:rounded-bl:first-child {
  border-bottom-left-radius: 0.25rem;
}

.last\:rounded-tr:last-child {
  border-top-right-radius: 0.25rem;
}

.last\:rounded-br:last-child {
  border-bottom-right-radius: 0.25rem;
}

.border-0 {
  border-width: 0px;
}

.border {
  border-width: 1px;
}

.border-t-0 {
  border-top-width: 0px;
}

.border-r-0 {
  border-right-width: 0px;
}

.border-r-4 {
  border-right-width: 4px;
}

.border-r {
  border-right-width: 1px;
}

.border-b-0 {
  border-bottom-width: 0px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-l-0 {
  border-left-width: 0px;
}

.border-solid {
  border-style: solid;
}

.border-transparent {
  border-color: transparent;
}

.border-color-primary {
  --tw-border-opacity: 1;
  border-color: rgba(221, 221, 221, var(--tw-border-opacity));
}

.border-bg-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgba(187, 187, 187, var(--tw-border-opacity));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgba(221, 221, 221, var(--tw-border-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}

.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
}

.bg-red-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
}

.bg-yellow-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
}

.bg-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
}

.object-cover {
  object-fit: cover;
}

.p-0 {
  padding: 0px;
}

.p-4 {
  padding: 1rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-10 {
  padding: 2.5rem;
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pr-6 {
  padding-right: 1.5rem;
}

.pb-0 {
  padding-bottom: 0px;
}

.pb-3 {
  padding-bottom: 0.75rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pl-0 {
  padding-left: 0px;
}

.pl-6 {
  padding-left: 1.5rem;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.capitalize {
  text-transform: capitalize;
}

.italic {
  font-style: italic;
}

.text-black {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}

.hover\:text-blue-800:hover {
  --tw-text-opacity: 1;
  color: rgba(30, 64, 175, var(--tw-text-opacity));
}

*, ::before, ::after {
  --tw-shadow: 0 0 #0000;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-lg:hover {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

*, ::before, ::after {
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
}

.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-gray-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
}

.filter {
  --tw-blur: var(--tw-empty,/*!*/ /*!*/);
  --tw-brightness: var(--tw-empty,/*!*/ /*!*/);
  --tw-contrast: var(--tw-empty,/*!*/ /*!*/);
  --tw-grayscale: var(--tw-empty,/*!*/ /*!*/);
  --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
  --tw-invert: var(--tw-empty,/*!*/ /*!*/);
  --tw-saturate: var(--tw-empty,/*!*/ /*!*/);
  --tw-sepia: var(--tw-empty,/*!*/ /*!*/);
  --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/);
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
          filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.blur {
  --tw-blur: blur(8px);
}

.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.transition {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.pypestream-loader > div {
  text-align: center;
  color: white;
  height: 8rem;
  width: 8rem;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.pypestream-loader > div > div:last-child {
  height: 72px;
  width: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: auto;
  position: absolute;
  top: -36px;
  left: 0;
  bottom: 0;
  right: 0;
}

.pypestream-loader > div > div:last-child path:last-child {
  fill: white;
}

@media (max-width: 1200px) {
  .smallScreen\:block {
    display: block;
  }

  .smallScreen\:hidden {
    display: none;
  }

  .smallScreen\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .smallScreen\:flex-col {
    flex-direction: column;
  }
}

@media (min-width: 1201px) {
  .desktopScreen\:block {
    display: block;
  }

  .desktopScreen\:flex {
    display: flex;
  }

  .desktopScreen\:hidden {
    display: none;
  }
}
  
/* purgecss start ignore */

html{
  font-family:"Open Sans",sans-serif !important;
  font-size:16px !important;
  height:100vh
}

body{
  width:auto;
  height:12vh;
  font-family:"Open Sans",sans-serif !important;
  margin:0;
  padding:0;
  -webkit-font-smoothing:antialiased;
  color:#29302e
}

#root{
  height:100vh;
  width:auto
}

.mt-0{
  margin-top:0
}

.text-core,p,a,h,h6,h4,h5,h2,h3,h1{
  color:#29302e;
  font-stretch:normal;
  font-style:normal;
  letter-spacing:normal
}

.text-gray{
  color:#716f6f
}

h1{
  font-size:26px;
  line-height:1.23;
  font-weight:normal
}

h2,h3{
  font-size:20px;
  line-height:1.2;
  font-weight:600;
  margin-top:0
}

h4,h5{
  font-size:18px;
  line-height:1.33;
  font-weight:600
}

h6{
  font-size:16px;
  line-height:1.5;
  font-weight:600
}

p,a{
  font-size:16px;
  line-height:1.5;
  font-weight:normal
}

p.body-header,a.body-header{
  font-weight:600
}

p.body-notation,a.body-notation{
  font-size:14px;
  font-style:italic;
  line-height:1.43;
  color:#7a6769
}

p.error,a.error{
  color:#e45f4f
}

a{
  color:#0a3ab4
}

a:hover{
  color:#062d9a;
  text-decoration:none
}

a:active{
  color:#042181;
  text-decoration:none
}

.manager{
  margin-top:12px
}

.manager .panel-heading{
  background-color:#f7f7f7;
  display:flex;
  justify-content:space-between;
  height:48px;
  font-size:20px;
  font-weight:500;
  color:#373a3c
}

.manager .panel-footer,.manager .breadcrumb{
  background-color:#eceeef
}

.manager .panel-footer{
  display:flex;
  justify-content:space-between
}

.manager .container-table-header{
  margin-top:24px;
  display:flex;
  align-items:center;
  justify-content:space-between
}

.manager .one-line{
  display:flex
}

.manager .description-line{
  font-size:16px;
  margin-top:12px;
  margin-bottom:25px;
  line-height:1.5
}

.manager .declaimer-line{
  height:56px;
  line-height:20px;
  border-radius:4px;
  border:solid 1px #bee5eb;
  background-color:#d1ecf1;
  font-size:16px;
  color:#0c5460;
  padding:16px
}

.manager .table{
  font-size:16px
}

.manager .glyphicon{
  position:static;
  font-size:12px
}

.manager .main-button{
  font-size:16px
}

.manager .btn-success{
  color:#fff;
  background-color:#28a745;
  border-color:#28a745
}

.manager .btn-primary{
  background-color:#0a3ab4;
  border-color:#0a3ab4
}

.manager .btn-secondary{
  background-color:#6c757d;
  border-color:#6c757d;
  color:#fff
}

.manager .btn-secondary[disabled]:hover{
  background-color:#b0b9c1;
  border-color:#b0b9c1;
  color:#fff
}

.manager .as-a-link{
  color:#337ab7;
  text-decoration:none
}

.manager .as-a-link:hover{
  cursor:pointer;
  color:#23527c;
  text-decoration:underline
}

.manager .s-alert-wrapper{
  margin-bottom:20px
}

.manager .s-alert-wrapper .alert{
  height:-webkit-fit-content;
  height:-moz-fit-content;
  height:fit-content;
  margin-bottom:0;
  padding:10px 23px;
  display:flex;
  justify-content:space-between;
  line-height:1.43
}

.manager .navbar{
  margin-bottom:0
}

.manager .tooltip-inner{
  background-color:#000;
  opacity:1;
  text-align:left;
  font-size:13px;
  padding:16px;
  border-radius:5px
}

.manager .env-version,.manager .capitalize{
  text-transform:capitalize;
  display:flex
}

.manager .table>tbody>tr>td{
  line-height:31px
}

.manager .buttons-container{
  display:flex;
  justify-content:flex-end
}

.manager .buttons-container>:not(:first-child){
  margin-left:8px
}

.manager .react-bs-container-body{
  min-height:200px
}

.manager .overview .react-bs-container-body{
  min-height:unset
}

.manager .pype-name{
  width:102px;
  height:18px;
  font-family:OpenSans;
  font-size:14px;
  font-weight:600;
  font-style:normal;
  font-stretch:normal;
  line-height:1.29;
  letter-spacing:normal;
  color:#373a3c
}

.manager .pype-description{
  width:305px;
  height:16px;
  font-family:OpenSans;
  font-size:12px;
  font-weight:normal;
  font-style:italic;
  font-stretch:normal;
  line-height:1.33;
  letter-spacing:normal;
  color:#d0021a
}

.logout-button{
  display:block;
  padding:3px 20px;
  clear:both;
  font-weight:400;
  line-height:1.42857143;
  color:#333;
  white-space:nowrap
}

.publish-modal .modal-dialog{
  max-width:610px;
  width:100%;
  border:solid 1px rgba(0,0,0,.2);
  border-radius:7px
}

.publish-modal .modal-dialog .modal-header{
  font-size:20px;
  font-weight:600;
  font-style:normal;
  font-stretch:normal;
  line-height:1;
  letter-spacing:normal;
  color:#373a3c;
  border-bottom:solid 1px rgba(0,0,0,.2);
  margin-bottom:32px
}

.publish-modal .modal-dialog .modal-header .version{
  width:70px
}

.publish-modal .modal-dialog .modal-header .btn{
  background-color:#eceeef;
  color:#373a3c
}

.publish-modal .modal-dialog .modal-header .btn:hover{
  box-shadow:none
}

.publish-modal .modal-dialog .modal-header .switch-btn .btn-default{
  width:auto;
  font-size:14px;
  font-weight:600;
  line-height:1.71;
  color:#043ac4;
  background:transparent;
  border:none
}

.publish-modal .modal-dialog .modal-header .switch-btn .btn-default i{
  font-size:12px;
  font-weight:normal;
  vertical-align:middle;
  margin-left:2px
}

.publish-modal .modal-dialog .modal-header .switch-btn .btn-default:hover{
  box-shadow:none
}

.publish-modal .modal-dialog .modal-body{
  font-size:14px;
  line-height:1.29;
  color:#373a3c;
  padding-bottom:32px
}

.publish-modal .modal-dialog .modal-body .btn-primary{
  height:auto;
  width:auto;
  padding:6px 12px;
  background-color:#043ac4;
  margin:10px 6px 0
}

.publish-modal .modal-dialog .modal-body .edit-btn{
  background-color:transparent;
  color:#043ac4;
  border-radius:4px;
  border:solid 1px #043ac4;
  font-style:normal
}

.publish-modal .modal-dialog .modal-footer{
  border-top:solid 1px rgba(0,0,0,.2);
  padding:13px
}

.publish-modal .modal-dialog .modal-footer .btn-info{
  background-color:transparent;
  width:110px;
  color:#043ac4;
  border-radius:4px;
  border:solid 1px #043ac4
}

.publish-modal .modal-dialog .modal-footer .btn-primary{
  background-color:#043ac4;
  width:110px
}

.publish-modal .modal-middle-content .control-label{
  text-transform:uppercase;
  color:#8a8a8a;
  font-size:12px;
  font-weight:500;
  line-height:1
}

.publish-modal .modal-middle-content .small-text{
  font-size:12px;
  font-style:italic;
  line-height:1.33;
  color:#696b77;
  padding-left:0;
  top:3px
}

.publish-modal .modal-middle-content .dropdown .dropdown-toggle{
  border:solid 1px #ccc;
  border-radius:4px;
  width:275px;
  font-size:16px;
  text-transform:capitalize;
  line-height:1.29;
  color:#373a3c;
  text-align:left;
  font-weight:400;
  padding:8px 10px;
  height:auto
}

.publish-modal .modal-middle-content .dropdown .dropdown-toggle .caret{
  border-top:6px dashed #000;
  border-right:5px solid transparent;
  border-left:5px solid transparent;
  border-radius:4px;
  margin-top:6px;
  float:right
}

.publish-modal .modal-middle-content .dropdown .dropdown-menu>li>a{
  width:273px
}

.publish-modal .show-all-btn .btn-default{
  width:auto;
  font-size:14px;
  font-weight:600;
  line-height:1.71;
  color:#043ac4;
  background:transparent;
  border:none;
  margin-left:-12px
}

.publish-modal .show-all-btn .btn-default i{
  font-size:12px;
  font-weight:normal;
  vertical-align:middle;
  margin-left:2px
}

.publish-modal .show-all-btn .btn-default:hover{
  box-shadow:none
}

.publish-modal .checkbox{
  display:inline-block;
  vertical-align:middle
}

.publish-modal .checkbox label{
  position:relative;
  padding:0;
  margin-right:8px;
  vertical-align:top
}

.publish-modal .checkbox label input[type=checkbox]{
  left:0;
  margin:0;
  top:0;
  width:100%;
  height:100%;
  opacity:0;
  z-index:2;
  cursor:pointer
}

.publish-modal .checkbox label .checkmark{
  border:2px solid #999;
  width:18px;
  height:18px;
  display:block
}

.publish-modal .checkbox label .checkmark:after{
  content:"";
  position:absolute;
  border-bottom:2px solid #043ac4;
  border-right:2px solid #043ac4;
  height:10px;
  width:7px;
  -webkit-transform:rotate(45deg);
  transform:rotate(45deg);
  left:6px;
  opacity:0;
  top:3px
}

.publish-modal .checkbox label input[type=checkbox]:checked+.checkmark:after{
  opacity:1
}

.pypes-list .row{
  border-radius:6px;
  margin:0 0 6px;
  transition:all .3s ease-in-out
}

.pypes-list .row:hover{
  background-color:#f4f4f4
}

.pypes-list .left .title_des,.pypes-list .right-column .title_des{
  display:inline-block;
  vertical-align:middle;
  width:85%
}

.pypes-list .left .title_des .pype-name,.pypes-list .right-column .title_des .pype-name{
  font-size:14px;
  text-transform:capitalize;
  font-weight:500;
  line-height:1.29;
  color:#373a3c;
  margin-bottom:0
}

.pypes-list .left .title_des .bot-name,.pypes-list .right-column .title_des .bot-name{
  font-size:18px;
  font-weight:600;
  line-height:2.29;
  color:#373a3c;
  margin:0
}

.pypes-list .left .title_des .pype-description,.pypes-list .right-column .title_des .pype-description{
  font-size:12px;
  line-height:1.33;
  color:#696b77;
  font-style:italic
}

.pypes-list .glyphicon{
  vertical-align:middle;
  color:#28a745;
  font-size:20px;
  padding-right:10px
}

.pypes-list .right{
  display:none;
  padding-top:7px
}

.pypes-list .right .radio{
  padding:0;
  margin:0
}

.pypes-list:hover .right{
  display:block
}

.radio{
  display:inline-block;
  vertical-align:middle;
  margin:0;
  padding:14px 0
}

.radio label{
  position:relative;
  padding:0;
  margin-right:8px;
  vertical-align:top
}

.radio label input[type=radio]{
  left:0;
  margin:0;
  top:0;
  width:100%;
  height:100%;
  opacity:0;
  z-index:2;
  cursor:pointer
}

.radio label .radiomark{
  display:block;
  font-size:12px;
  line-height:1.67;
  color:#696b77;
  padding-left:18px
}

.radio label .radiomark:before{
  content:"";
  position:absolute;
  left:0;
  top:3px;
  border:1px solid #696b77;
  width:12px;
  height:12px;
  border-radius:20px
}

.radio label .radiomark:after{
  content:"";
  position:absolute;
  height:8px;
  width:8px;
  border-radius:20px;
  opacity:0;
  background-color:#043ac4;
  left:2px;
  top:5px
}

.radio label input[type=radio]:checked+.radiomark:after{
  opacity:1
}

.radio+.radio{
  margin-top:0;
  margin-left:13px
}

.none-available .fa{
  vertical-align:middle;
  color:#d0021a;
  font-size:20px
}

.none-available .glyphicon{
  vertical-align:middle;
  color:#d0021a;
  font-size:20px;
  margin-left:10px
}

.none-available .none-left{
  display:inline-block;
  vertical-align:middle;
  margin-left:8px
}

.none-available .none-left h5{
  font-size:18px;
  font-weight:600;
  line-height:1.29;
  color:#373a3c;
  margin:0 0 5px
}

.none-available .none-left p,.none-available .none-left a{
  font-size:12px;
  font-weight:normal;
  font-style:italic;
  line-height:1.33;
  color:#d0021a;
  margin:0
}

.middle-content{
  max-height:310px;
  overflow:auto
}

.text-error--small{
  margin-top:8px;
  margin-left:16px;
  font-size:12px;
  color:#e45f4f;
  line-height:1
}

.bodyContainer{
  display:flex;
  margin:0 108px;
  padding-bottom:5rem
}

.bodyContainer .routeContainer{
  width:100%;
  width:-webkit-stretch;
  width:-webkit-fill-available;
  width:stretch
}

/* purgecss end ignore */
/* purgecss start ignore */

.pypestream-manager-loader{
  display:flex;
  justify-content:center;
  align-items:center;
  overflow:hidden
}

.pypestream-manager-loader.center{
  position:fixed;
  width:100%;
  height:100vh;
  top:50%;
  left:50%;
  -webkit-transform:translate(-50%, -50%);
  transform:translate(-50%, -50%)
}

.pypestream-manager-loader>div{
  min-width:128px;
  min-height:128px;
  height:128px;
  width:128px;
  position:relative
}

/* purgecss end ignore */
/* purgecss start ignore */

.previewPanel #pypestream-wrapper{
  height:calc(100vh - 80px) !important
}

/* purgecss end ignore */
/* purgecss start ignore */

.icon-states{
  cursor:pointer
}

.icon-states path{
  fill:#0a3ab4
}

.icon-states circle{
  fill:transparent
}

.icon-states:hover circle{
  fill:#cbdffb
}

.icon-states:active circle{
  fill:#99bdf7
}

.manager .drawer{
  font-size:16px;
  background-color:#fff;
  text-overflow:ellipsis;
  white-space:nowrap;
  overflow:hidden
}

.manager .drawer-head{
  font-size:16px;
  font-weight:500;
  background-color:#f5f5f5;
  border:1px solid #dee2e6;
  border-radius:4px 4px 0 0
}

.manager .drawer-row{
  height:48px;
  padding-left:20px;
  line-height:50px;
  border-bottom:1px solid #dee2e6;
  border-right:1px solid #dee2e6;
  border-left:1px solid #dee2e6;
  cursor:pointer;
  display:flex;
  align-items:center
}

.manager .drawer-row img{
  margin-right:15px
}

.manager .drawer-row.selected{
  color:#000;
  background-color:#eceeef
}

.manager .drawer-row:last-child{
  border-radius:0 0 4px 4px
}

.manager .section-title{
  display:flex;
  justify-content:space-between
}

.manager .download-version{
  font-size:16px;
  font-weight:normal;
  color:#29302e
}

.manager .version-table-menu{
  display:flex;
  justify-content:flex-end
}

.manager .version-table-menu .dot-menu-icon svg g path{
  fill:#bbb
}

.manager .version-table-menu .dot-menu-icon:hover svg g path{
  fill:#0a3ab4
}

.manager .version-action-menu{
  display:flex;
  justify-content:flex-end;
  align-items:center
}

.manager .version-show-more{
  display:flex;
  align-items:center;
  margin:20px 0 64px;
  height:32px;
  padding:0 10px;
  cursor:pointer
}

.manager .version-show-more>span{
  font-size:14px;
  font-weight:600;
  color:#0a3ab4
}

.manager .version-show-more>svg{
  -webkit-transform:rotate(90deg);
  transform:rotate(90deg)
}

.manager .version-show-more>svg path{
  fill:#0a3ab4
}

.manager .env-version{
  color:#7a6769;
  font-size:16px;
  font-weight:normal
}

.manager .env-version h4{
  margin:0
}

.manager .env-version span{
  margin:1px 3px;
  height:28px;
  border-radius:4px;
  line-height:22px;
  font-weight:bold;
  float:right
}

.manager .env-version .label{
  background-color:#0e0e0e
}

.manager .env-version .prod,.manager .env-version .local{
  background-color:#6610f2
}

.manager .env-version .sandbox{
  background-color:#fd7e14
}

.manager .env-version .qa{
  background-color:#5cb85c
}

.manager .env-version .claybox,.manager .env-version .ce{
  background-color:#337ab7
}

.manager .restore-box{
  display:block;
  height:29px;
  border-radius:4px;
  border:solid 1px #0275d8;
  font-size:14px;
  line-height:1.5;
  color:#007bff;
  font-weight:normal;
  cursor:pointer;
  transition:background-color 1s,color 1s,border 1s;
  float:right
}

.manager .restore-box-color{
  border:solid 1px #043ac4;
  color:#043ac4;
  font-weight:500
}

.manager .copied{
  border-radius:4px;
  font-size:14px;
  line-height:1.5;
  color:#fff;
  border:solid 1px #28a745;
  background-color:#28a745;
  transition:background-color 1s,color 1s,border 1s
}

.manager .info-icon{
  background-color:transparent;
  border-radius:50%;
  padding:0px 6px;
  color:#000;
  font-size:10px;
  position:relative;
  bottom:2px;
  border:1px solid #000
}

.manager .info{
  background-color:#0067f3;
  border-radius:50%;
  padding:0 5px;
  color:#fff;
  font-size:10px;
  position:relative;
  bottom:2px
}

.manager .bot-upload-modal-line{
  display:flex;
  justify-content:space-between;
  font-size:13px;
  font-weight:bold;
  color:rgba(55,58,60,.4);
  margin:7px 0
}

.manager .bot-upload-modal-line.active{
  color:#373a3c
}

.manager .bot-upload-modal-line .glyphicon{
  color:#28a745
}

.manager .label.draft{
  height:28px;
  border-radius:4px;
  background-color:#6c757d;
  line-height:22px
}

.manager .toast{
  position:absolute;
  opacity:0;
  width:calc(100% - 30px);
  height:-webkit-fit-content;
  height:-moz-fit-content;
  height:fit-content;
  box-shadow:0 8px 16px 0 rgba(0,0,0,.15);
  border:solid 1px #c3e6cb;
  transition:opacity 1s;
  line-height:32px;
  z-index:3
}

.manager .test-table{
  font-size:16px;
  color:#373a3c;
  table-layout:fixed
}

.manager .table>tbody>tr>td,.manager .table>tbody>tr>th,.manager .table>tfoot>tr>td,.manager .table>tfoot>tr>th,.manager .table>thead>tr>td,.manager .table>thead>tr>th{
  border:none;
  border-radius:4px;
  font-weight:600;
  color:#29302e
}

.manager .table>tbody>tr>td:focus,.manager .table>tbody>tr>th:focus,.manager .table>tfoot>tr>td:focus,.manager .table>tfoot>tr>th:focus,.manager .table>thead>tr>td:focus,.manager .table>thead>tr>th:focus{
  outline:none
}

.manager .table>tbody>tr>td:hover,.manager .table>tbody>tr>th:hover,.manager .table>tfoot>tr>td:hover,.manager .table>tfoot>tr>th:hover{
  background-color:#f9f9f9;
  color:#0a3ab4
}

.manager .table>thead>tr>th{
  font-size:13px;
  font-weight:600
}

.manager .table>thead>tr{
  font-size:13px;
  font-weight:600;
  border-bottom:1px solid #ddd
}

.manager .publish-line{
  font-size:13px;
  font-style:italic;
  line-height:1.48;
  color:#373a3c;
  margin-right:10px
}

.manager .inner-addon{
  position:relative
}

.manager .inner-addon .glyphicon{
  position:absolute;
  padding:10px;
  pointer-events:none;
  font-size:16px
}

.manager .left-addon .glyphicon{
  left:0px
}

.manager .right-addon .glyphicon{
  right:0px
}

.manager .left-addon input{
  padding-left:30px
}

.manager .right-addon input{
  padding-right:30px
}

.manager .download-arrow{
  width:18px;
  margin:-1px 4px 0 0;
  text-align:end
}

.manager .delete-trashcan{
  cursor:pointer;
  margin-bottom:-7px
}

.manager .delete-trashcan:hover g circle{
  fill:#ccdffa
}

.manager .delete-trashcan:active g circle{
  fill:#98bdf7
}

.manager .not-uploaded{
  color:#ccc;
  font-size:18px
}

.manager .ready-to-upload{
  color:#ffbc00;
  font-size:18px
}

.manager .uploaded{
  color:#1cab13;
  font-size:9px;
  border:2px solid #28a745;
  border-radius:50%;
  padding:3px 3px;
  font-weight:bold
}

.manager .file-state-column{
  display:flex;
  align-items:center;
  line-height:31px
}

.manager .error-Sign{
  color:#d0021a;
  font-size:medium
}

.manager .error,.manager .error-modal,.manager .error-modal .bot-upload-modal-line,.manager .error-modal .bot-upload-modal-line .glyphicon{
  color:#d0021b
}

.manager .error-modal .progress-bar,.manager .error-modal .btn-primary{
  background-color:#d0021b;
  border-color:#d0021b
}

.manager .control-label{
  font-size:16px;
  font-weight:bold;
  color:#373a3c;
  margin-bottom:4px
}

.manager .conversation-button{
  width:170px;
  height:35px;
  font-size:16px;
  background-color:#043ac4
}

.manager .text-anchor{
  cursor:pointer;
  color:#0a3ab4;
  font-size:16px;
  font-weight:400
}

.manager .Intent_content .description-line{
  width:50%;
  float:left;
  margin-top:0;
  padding-right:24px
}

.manager .Intent_content{
  display:inline-block;
  width:100%
}

.manager .Intent_content .fuzzy-matching-title{
  font-weight:600;
  font-size:16px;
  line-height:1.5;
  color:#29302e
}

.manager .Intent_content .fuzzy-matching-description{
  font-size:16px;
  line-height:1.5;
  color:#29302e
}

.manager .Intent_content .fuzzy-matching-switch-container{
  display:flex;
  align-items:center;
  margin-top:12px;
  color:#7a6769
}

.manager .Intent_content .fuzzy-matching-switch-container .fuzzy-matching-switch{
  margin-left:0px;
  margin-right:7px
}

.manager .Intent_content .fuzzy-matching-switch-container .react-switch-handle{
  z-index:9
}

.manager .Intent_content .fuzzy-matching-switch-container .react-switch-handle:hover{
  background:#0a3ab4 !important
}

.manager .Intent_content .description-line+.description-line{
  padding:24px;
  background-color:#eef2ff;
  line-height:24px;
  position:relative;
  float:right;
  border-radius:4px
}

.manager .Intent_table_data table tr td,.manager .Intent_table_data table tr th{
  border-bottom:none;
  font-weight:normal
}

.manager .Intent_table_data table tr th+th{
  color:#7a6769;
  font-weight:normal
}

.manager .Intent_table_data table tr:hover td:nth-child(1) span:nth-child(1){
  color:#0641cb
}

.manager .Intent_table_data table tr td:last-child{
  opacity:0
}

.manager .Intent_table_data table tr:hover td:last-child{
  opacity:1
}

.manager .Intent_table_data table tr td:first-child,.manager .Intent_table_data table tr th:first-child{
  width:200px !important
}

.manager .Intent_table_data table tr td:first-child{
  width:200px
}

.manager .Intent_table_data table tr td:hover{
  cursor:pointer
}

.manager .Intent_table_data .s-alert-wrapper{
  display:none
}

.manager .table-subdata{
  color:#b52c1c
}

.manager .table-header-subdata{
  color:#716f6f
}

.manager .errorSection{
  position:relative;
  top:-25px;
  color:#b52c1c
}

.manager .errorSection span.warning{
  position:absolute;
  right:0;
  top:-31px;
  border-bottom:#b52c1c solid 1px;
  width:100%;
  text-align:right;
  pointer-events:none
}

.manager .back-div{
  display:flex
}

.manager .back-div a{
  margin-top:5px
}

.manager .back-div .text_anchor:hover{
  text-decoration:none;
  cursor:pointer
}

.manager .Intent_table_data table tr td:nth-child(2),.manager .Intent_table_data table tr th:nth-child(2){
  width:60%;
  overflow:visible;
  white-space:pre-wrap
}

.manager .Add_intent_button{
  line-height:30px;
  margin-bottom:10px;
  font-size:16px
}

.manager .Add_intent_button h4{
  display:inline
}

.manager .Add_intent_button strong{
  font-size:18px
}

.manager .back-div .text_anchor>svg{
  margin-top:5px
}

.manager .text_anchor{
  height:24px;
  font-size:16px;
  font-weight:500;
  font-stretch:normal;
  font-style:normal;
  letter-spacing:normal;
  color:#0641cb
}

.manager .Intent_button_with_table{
  display:grid;
  grid-template-columns:auto auto;
  grid-gap:.5rem;
  align-items:center;
  float:right;
  margin-bottom:15px
}

.manager .Intent_button_with_table .dot-menu-icon>*{
  top:-8px
}

.manager .Intent_button_without_table{
  display:flex
}

.manager .Intent_table_data table tr td:first-child div{
  padding-left:10px;
  line-height:1.5
}

.manager .Intent_table_data table tr td:nth-child(2) div{
  line-height:1.5
}

.manager .Intent_table_data table tr td:last-child{
  text-align:right !important;
  padding-right:16px
}

.manager .Intent_table_data table tr td:last-child div{
  padding-top:8px
}

.manager .Intent_table_data table tr:hover td:last-child{
  text-align:right !important;
  padding-right:16px
}

.manager .Intent_table_data table tr:hover td:last-child div{
  padding-top:8px
}

.manager .name_header{
  padding-left:18px
}

.manager .intent_table_utterance_header{
  padding-left:8px
}

.manager .save_open_icon{
  margin-right:1px !important;
  color:blue !important;
  border:none !important;
  background:none !important;
  margin-top:10px
}

.manager .save_open_icon:active{
  background:transparent !important;
  box-shadow:none
}

.manager .save_open_icon:focus{
  border:none !important;
  outline:none
}

.manager .uttrence_title{
  display:flex;
  align-items:center;
  margin-top:20px;
  justify-content:space-between
}

.manager .uttrence_title h5{
  font-size:18px
}

.manager .intent_sub_name{
  width:20%;
  margin-left:-313px
}

.manager .intent_title input{
  text-transform:capitalize;
  width:115%
}

.manager .intent_sub_name input{
  text-align:end;
  color:#7a6769
}

.manager .Intent_subtitle{
  margin-top:-20px;
  margin-bottom:30px;
  color:#7a6769;
  padding:2px 16px
}

.manager .Utterence_table .react-bs-container-body,.manager .Utterence_table .react-bs-container-body table td{
  overflow:visible
}

.manager .Utterence_table .react-bs-container-body table td div div input:focus,.manager .Utterence_table .react-bs-container-body table td div div input:hover{
  background-color:#f9f9f9 !important
}

.manager .Utterence_table .react-bs-container-body table tr td:first-child,.manager .Utterence_table .react-bs-container-header table tr th:first-child{
  width:50%
}

.manager .Utterence_table .react-bs-container-body table tr td div div,.manager .Utterence_table .react-bs-container-body table tr td div div input{
  background-color:transparent
}

.manager .Utterence_table .react-bs-container-body table tr td:nth-child(2),.manager .Utterence_table .react-bs-container-header table tr th:nth-child(2){
  width:25%
}

.manager .Utterence_table .react-bs-container-body table tr td:nth-child(3),.manager .Utterence_table .react-bs-container-header table tr th:nth-child(3){
  width:18%
}

.manager .Utterence_table .react-bs-container-body table tr td{
  border-bottom:none
}

.manager .Utterence_table table tr td:first-child div div div{
  left:8px
}

.manager .Utterence_table .react-bs-container-header table tr th{
  font-size:13px;
  line-height:16px;
  color:#7a6769;
  border-bottom:none;
  letter-spacing:0
}

.manager .Utterence_table .react-bs-container-header table tr th:hover{
  color:#000
}

.manager .Utterence_table input{
  text-transform:capitalize
}

.manager .Utterence_title button svg path{
  fill:#083ab4
}

.manager .utterance_header{
  padding:0 32px
}

.manager .Utterence_table table tr td:last-child{
  text-align:right !important;
  padding-right:16px
}

.manager .Utterence_table table tr td:last-child div{
  padding-top:8px
}

.manager .Utterence_table table tr:hover td:last-child{
  text-align:right !important;
  padding-right:16px
}

.manager .Utterence_table table tr:hover td:last-child div{
  padding-top:8px
}

.manager .utterance_data{
  height:32px
}

.manager .utterance_data input{
  text-transform:capitalize
}

.manager .uttrence_title .text_anchor{
  display:flex;
  width:auto
}

.manager .uttrence_title .text_anchor:hover{
  text-decoration:none;
  cursor:pointer
}

.manager .uttrence_title .intent_title{
  position:relative;
  width:50%
}

.manager .uttrence_title .intent_title label.MuiFormLabel-root{
  background:#fff;
  font-size:16px !important
}

.manager .uttrence_title .intent_title>span{
  position:absolute;
  right:-200px;
  top:6px;
  font-size:16px;
  line-height:24px;
  color:#7a6769;
  letter-spacing:0;
  overflow:hidden;
  white-space:nowrap;
  text-overflow:ellipsis;
  width:150px
}

.manager .warning{
  font:"48px";
  color:"red"
}

.manager #intent_modal .modal-header{
  padding:15px 50px;
  border-bottom:0;
  box-shadow:1px 1px 9px -2px #c3c3c3;
  background-color:#fff
}

.manager #intent_modal .modal-header h4{
  font-size:18px;
  line-height:24px;
  font-weight:600;
  letter-spacing:0
}

.manager #intent_modal .modal-footer{
  border-top:0;
  padding:15px 25px 40px
}

.manager #intent_modal .modal-footer a.text_anchor{
  display:inline-block;
  margin-right:10px;
  cursor:pointer
}

.manager #intent_modal .modal-body{
  height:100px;
  font-size:16px;
  line-height:24px;
  padding:15px 50px
}

.manager #intent_modal .modal-dialog{
  width:40%
}

.manager .stop_word_modal .modal-dialog{
  width:100% !important;
  max-width:908px
}

.manager .stop_word_modal .modal-body{
  height:auto !important;
  margin-left:1px;
  font-size:16px !important;
  font-family:"Open Sans"
}

.manager .stopword_area{
  width:100%;
  border-radius:5px;
  height:128px;
  position:relative;
  left:-1px;
  z-index:0;
  top:-4px;
  resize:none;
  padding:16px 17px 0px 16px
}

.manager .stopword_header{
  color:#8e8e8e;
  padding:0 5px;
  background:#fff;
  position:relative;
  top:10px;
  left:12px;
  z-index:9;
  font-size:12px
}

.manager .stopword_modal_body{
  padding:25px 40px 0 !important;
  background:none !important
}

.manager .stopword_modal .modal-content{
  background:#fff
}

.manager .stopword_modal_header{
  padding:18px 40px !important
}

.manager .stopword_cancel_button{
  margin-right:7px !important
}

.manager .stopword_modal_header .modal-title{
  font-family:"Open Sans";
  font-size:18px;
  font-weight:600;
  font-stretch:normal;
  font-style:normal;
  line-height:1.33;
  letter-spacing:normal;
  color:#29302e
}

.manager .stopword_text{
  margin-top:10px;
  font-size:20px
}

.manager .stopword_link{
  font-size:16px
}

.manager .stopword_link.disabled{
  opacity:.65
}

.manager .stopword_link.disabled:hover{
  cursor:not-allowed
}

.manager .stopword_link:hover{
  text-decoration:none;
  cursor:pointer
}

.manager .modal-content{
  border-radius:0;
  background-color:#f9f9f9
}

.manager .modal-content .modal-footer button+button{
  margin-left:5px
}

.manager .table-header-data span:first-child{
  text-transform:capitalize;
  font-weight:600;
  color:#29302e
}

.manager .uttranceRowData{
  max-height:90px;
  text-transform:capitalize
}

.manager .entity_table_data{
  overflow:unset
}

.manager .publsh-tooltip img{
  height:16px
}

.manager .publsh-tooltip{
  display:inline-block;
  margin-right:5px
}

.manager .createNewInterface-container{
  display:block;
  margin:2rem 8rem;
  background-color:#fff
}

@media(max-width: 1200px){
  .manager .createNewInterface-container{
    display:none
  }
}

.manager .createNewInterface-container-content{
  display:flex;
  flex-direction:row;
  font-family:"Open Sans";
  font-size:16px
}

.manager .createNewInterface-container-content--left{
  width:45.5%;
  padding-right:24px;
  font-size:16px
}

.manager .createNewInterface-container-content--left .MuiTypography-body1{
  font-family:"Open Sans";
  font-size:16px;
  line-height:1.5
}

.manager .createNewInterface-container-content--left .helperText{
  margin-left:16px;
  font-family:"Open Sans";
  font-size:12px;
  line-height:1;
  color:red
}

.manager .createNewInterface-container-content--left .altHelperText{
  margin-left:16px;
  font-family:"Open Sans";
  font-size:12px;
  line-height:1;
  color:#8e8e8e
}

.manager .createNewInterface-container-content--left .img-circle{
  background-color:#ddd;
  height:4rem;
  width:4rem;
  border-radius:100px;
  margin-right:24px;
  align-self:center;
  display:flex;
  align-items:center;
  justify-content:center;
  cursor:pointer
}

.manager .createNewInterface-container-content--left .img-circle svg g g g circle{
  fill:transparent
}

.manager .createNewInterface-container-content--left h3{
  font-family:"Open Sans";
  font-weight:600;
  line-height:1.5;
  font-size:16px;
  margin-top:40px
}

.manager .createNewInterface-container-content--left p{
  font-family:"Open Sans";
  font-size:16px;
  font-weight:normal;
  line-height:1.5;
  color:#29302e;
  margin:0;
  padding:0
}

.manager .createNewInterface-container-content--left .color-checkboxes{
  padding:0;
  margin-right:8px;
  margin-top:6px;
  width:18px;
  height:18px;
  align-self:flex-end
}

.manager .createNewInterface-container-content--left .lock-icon{
  cursor:pointer
}

.manager .createNewInterface-container-content--left .input-style{
  font-size:16px;
  line-height:1.5;
  font-family:"Open Sans"
}

.manager .createNewInterface-container-content--left .input-label-style{
  font-family:"Open Sans"
}

.manager .createNewInterface-container-content--right{
  width:55%
}

.manager .createNewInterface-container-content--right .widget-preview-container{
  width:100%;
  height:50.7142857143rem;
  background-color:#f3f3f3;
  display:flex;
  align-items:center;
  flex-direction:column
}

.manager .createNewInterface-container-content--right .widget-preview-container .widget-preview-main{
  margin-top:2.86rem;
  box-shadow:0 10px 30px 0 rgba(0,0,0,.2);
  border-radius:10px
}

.manager .createNewInterface-container-content--right .widget-preview-container .widget-preview-main__header{
  display:flex;
  justify-content:space-between;
  height:5rem;
  border-radius:10px 10px 0 0;
  color:#fff
}

.manager .createNewInterface-container-content--right .widget-preview-container .widget-preview-main__header--left{
  display:flex;
  align-items:center;
  vertical-align:middle
}

.manager .createNewInterface-container-content--right .widget-preview-container .widget-preview-main__header--left p{
  font-family:"Open Sans";
  font-size:16px;
  font-weight:600;
  margin:0
}

.manager .createNewInterface-container-content--right .widget-preview-container .widget-preview-main__header--left .brand-icon{
  height:2.14rem;
  width:2.14rem;
  background-color:#ddd;
  border-radius:100px;
  margin-right:16px
}

.manager .createNewInterface-container-content--right .widget-preview-container .widget-preview-main__header--right{
  display:flex;
  align-items:center;
  vertical-align:center
}

.manager .createNewInterface-container-content--right .widget-preview-container .widget-preview-main__body{
  height:27.3rem;
  background-color:#fff;
  padding-top:20px
}

.manager .createNewInterface-container-content--right .widget-preview-container .widget-preview-main__body-bubble--left{
  margin-left:28px;
  align-items:center;
  border-radius:5px;
  display:flex;
  max-width:290px;
  padding:15px 0 15px 20px;
  font-family:"Open Sans";
  font-size:16px;
  position:relative
}

.manager .createNewInterface-container-content--right .widget-preview-container .widget-preview-main__body-bubble--right{
  border-radius:5px;
  display:flex;
  margin:20px 24px 20px auto;
  max-width:223px;
  padding:15px 0 15px 15px;
  font-family:"Open Sans";
  font-size:16px;
  color:#fff
}

.manager .createNewInterface-container-content--right .widget-preview-container .widget-preview-main__body-button{
  border-radius:5px;
  padding:15px 20px;
  display:inline-block;
  margin:15px 0 0 5px;
  font-family:"Open Sans";
  font-size:14px;
  font-weight:600;
  height:50px
}

.manager .createNewInterface-container-content--right .widget-preview-container .widget-preview-main__body small{
  color:#afbed3;
  font-size:9px;
  font-family:"Open Sans";
  display:flex;
  align-items:center;
  justify-content:center;
  margin-top:2px
}

.manager .createNewInterface-container-content--right .widget-preview-container .widget-preview-main__footer{
  height:4.9rem;
  background-color:#f8fbff;
  border-radius:0 0 10px 10px;
  border-top:1px solid #dee8f5;
  display:flex;
  justify-content:space-between;
  flex-direction:row;
  padding:26px 20px
}

.manager .createNewInterface-container-content--right .widget-preview-container .launcher-button{
  margin-top:40px;
  width:70px;
  height:70px;
  border-radius:100px;
  display:flex;
  justify-content:center;
  align-items:center;
  box-shadow:15px 10px 40px 0 rgba(0,0,0,.5);
  margin-right:58px;
  align-self:flex-end
}

.manager .createNewInterface-container-content--rightside{
  position:fixed;
  right:12%;
  display:flex;
  flex-direction:column;
  background-color:#f3f3f3
}

.manager .preview-button{
  margin-right:8px
}

.manager .preview_button{
  margin-left:16px;
  height:40px;
  border-radius:4px;
  border:solid 1px #0275d8;
  color:#007bff;
  font-weight:400;
  margin-right:0;
  padding:10px 12px;
  background:none
}

.modal-content{
  background-clip:unset !important;
  border-radius:0
}

.intent_modal .intent_modal_header{
  border:0;
  box-shadow:1px 1px 9px -2px #c3c3c3;
  background-color:#fff;
  padding-left:24px
}

.intent_modal .intent_modal_body{
  background:none;
  padding:16px 24px
}

.intent_modal .intent_modal_footer{
  border:0;
  padding:16px 24px
}

.intent_modal .stopword_area{
  width:100%;
  border-radius:5px;
  height:128px;
  position:relative;
  left:-1px;
  z-index:0;
  top:-4px;
  resize:none;
  padding:16px 17px 0px 16px
}

.intent_modal .stopword_header{
  color:#8e8e8e;
  padding:0 5px;
  background:#fff;
  position:relative;
  top:10px;
  left:12px;
  z-index:9;
  font-size:12px
}

.interfaces-tab-list{
  display:flex;
  margin-bottom:40px
}

.interfaces-tab-list-preview{
  margin-right:16px
}

.interfaces-tab-list-preview--rectangle{
  width:100px;
  height:24px;
  border-radius:4px 4px 0 0
}

.interfaces-tab-list-preview-bubbles{
  display:flex;
  flex-direction:column;
  width:100px;
  height:76px;
  border-radius:0 0 4px 4px;
  padding:6px 4px
}

.interfaces-tab-list-preview-bubbles--user-bubble{
  align-self:flex-end
}

.interfaces-tab-list-info{
  display:inherit;
  width:100%
}

.interfaces-tab-list-info div:first-of-type{
  margin-right:22px;
  width:182px
}

.interfaces-tab-list-info div:nth-of-type(2){
  margin-right:95px;
  width:250px
}

.interfaces-tab-list-info div:nth-of-type(3){
  width:13rem
}

.interfaces-tab-list-info small{
  font-size:14px;
  font-style:italic;
  line-height:1.43;
  color:#7a6769
}

.interfaces-tab-list-info button{
  align-self:flex-end
}

.interfaces-tab-list-info .trashcan-icon{
  cursor:pointer
}

.interfaces-tab-list-info .trashcan-icon:hover g g g circle{
  fill:#cbdffa !important
}

.interfaces-tab-list-info .trashcan-icon:active g g g circle{
  fill:#98bdf7 !important
}

.interfaces-tab-list-info--informational-text{
  color:#7a6769
}

.copy-icon{
  cursor:pointer;
  margin:0 8px;
  align-self:flex-end
}

.copy-icon:hover g g g circle{
  fill:#cbdffa
}

.copy-icon:active g g g circle{
  fill:#98bdf7
}

.newNavbarContainer{
  height:5.7142857143rem;
  box-shadow:0 0 15px 0 rgba(0,0,0,.1),0 0 6px 0 rgba(0,0,0,.1);
  background-color:#fff;
  padding:12px 19vw;
  display:flex;
  align-items:center;
  justify-content:space-between;
  width:100vw !important;
  position:fixed;
  top:0;
  left:0;
  z-index:5
}

.newNavbarContainer-leftContent{
  display:inherit;
  align-items:center
}

.newNavbarContainer-leftContent h3{
  margin:0;
  padding-left:16px;
  font-size:16px;
  font-weight:600;
  font-family:"Open Sans";
  line-height:1.5;
  color:#7a6769
}

.current-version-list table{
  width:calc(100% - 12px)
}

.current-version-list table thead th{
  padding:.5rem !important
}

.current-version-list table tbody td{
  padding:.5rem !important
}

.training-utterance-table{
  display:unset
}

.previewModal{
  overflow:hidden !important;
  display:flex !important;
  margin-left:auto !important;
  width:35.7rem !important
}

.previewModal .modal-header{
  border:0
}

.previewModal .preview_Header{
  padding:25px 23px !important;
  background-color:#fff;
  box-shadow:none;
  z-index:99999999999999
}

.previewModal .preview_Header span{
  float:right
}

.previewModal .preview_Header span>img:first-child{
  padding-right:7px
}

.previewModal .preview_modal_body{
  background:#fff;
  border-top:#ccc solid 1px;
  padding:0 !important
}

.solution-dialog-draft-cards{
  display:grid;
  grid-template-columns:2fr 1fr 1fr;
  grid-column-gap:24px;
  margin-top:40px;
  margin-bottom:24px
}

.solution-dialog .draft-card-actions{
  display:flex;
  align-items:center
}

.publish-button,.train-solution-button{
  margin-right:0
}

.dialog-version-card{
  padding:16px 16px 20px;
  border-radius:4px;
  background-color:#eef2ff;
  position:relative
}

.dialog-version-card.secondary{
  border:1px solid #eef2ff;
  background-color:#fff
}

.dialog-version-card-header{
  display:flex;
  align-items:center
}

.dialog-version-card-title{
  font-size:16px;
  font-weight:600;
  color:#29302e;
  margin-right:8px;
  text-transform:capitalize
}

.dialog-version-card-version{
  font-size:16px;
  font-weight:normal;
  color:#716f6f
}

.dialog-version-card-desc{
  margin:8px 0 16px;
  font-size:14px;
  font-weight:normal;
  font-style:italic;
  color:#716f6f
}

.dialog-version-card-dotmenu{
  position:absolute;
  top:16px;
  right:16px
}

.solution-header{
  padding:12px 16px;
  background-color:#eef2ff;
  border-radius:4px
}

.solution-header-top{
  display:flex;
  justify-content:space-between;
  align-items:center;
  min-height:32px
}

.solution-header-top-left{
  display:flex;
  align-items:center
}

.solution-header-top-left:not(.error):not(.loading) g{
  fill:#249d6f
}

.solution-header-title{
  margin-left:4px;
  font-size:16px;
  font-weight:normal;
  color:#29302e
}

.solution-header-buttons{
  display:flex;
  flex-wrap:nowrap
}

.solution-header-error-content p{
  font-size:1rem;
  font-weight:normal;
  color:#29302e;
  margin:1rem 2rem
}

.solution-header-error-content-footer{
  display:flex;
  justify-content:flex-end
}

.bot_layout{
  display:flex;
  flex-direction:row;
  margin-top:2rem
}

@media(max-width: 1200px){
  .bot_layout{
    flex-direction:column
  }
}

.bot_layout__left{
  margin-right:60px;
  margin-bottom:40px
}

@media(max-width: 1200px){
  .bot_layout__left{
    display:none
  }
}

.bot_layout__right{
  width:100%;
  width:-webkit-fill-available;
  width:fill-available
}

.menu__small_screen{
  display:none;
  margin:1rem 0 2rem 0
}

@media(max-width: 1200px){
  .menu__small_screen{
    display:block
  }
}

.menu__small_screen__notice{
  background-color:#f8f8f8;
  padding:1rem;
  border-radius:4px
}

.menu__small_screen__notice-text{
  color:#716f6f;
  margin-bottom:0;
  font-size:.9rem
}

.settings-page-delete{
  margin-top:55px
}

.delete-solution-modal-container>p{
  font-size:16px;
  line-height:1.5;
  color:#29302e;
  margin-bottom:1rem
}

.delete-solution-modal-container>p:first-child{
  margin-bottom:2rem
}

.pype-css-editor{
  min-height:300px;
  display:flex;
  flex-direction:column;
  margin-top:24px
}

.pype-css-editor>*{
  flex:1 1
}

.action-container{
  width:calc(100vw - 216px)
}

.action-container>section{
  overflow:hidden
}

.channels-tab-title{
  margin-bottom:1rem
}

.channels-tab-description{
  display:grid;
  grid-template-columns:1fr auto
}

.channels-tab-description>p{
  font-size:16px
}

.channels-tab-description>button{
  margin-left:3rem;
  margin-right:0
}

.channels-tab-table{
  margin-top:40px
}

.channels-tab-placeholders{
  display:grid;
  grid-template-rows:auto;
  grid-row-gap:28px;
  row-gap:28px;
  margin-top:14px
}

.channel-list-item{
  display:grid;
  grid-template-columns:auto 1fr;
  padding:14px 0
}

.channel-list-item-interface .interface-name{
  font-weight:600;
  font-size:16px;
  margin-bottom:0;
  white-space:nowrap
}

.channel-list-item-interface span{
  color:#29302e;
  font-size:16px;
  margin-bottom:0
}

.channel-list-item-interface p{
  width:auto;
  font-size:16px;
  margin-bottom:0
}

.channel-list-environment{
  height:100%;
  padding:14px 0
}

.channel-list-environment>p{
  color:#29302e;
  font-size:16px;
  margin-bottom:0;
  line-height:1.5
}

.channel-list-environment>.pype{
  color:#716f6f
}

.channels-list:hover{
  background-color:#f9f9f9;
  cursor:pointer
}

.channel-list-lastedited{
  height:100%;
  padding:14px 0
}

.channel-list-lastedited>p{
  color:#29302e;
  font-size:16px;
  margin-bottom:0;
  line-height:1.5
}

.channel-list-menus{
  height:calc(100% - 14px);
  display:flex;
  justify-content:center
}

.channel-list-menus>div>button{
  margin-top:6px;
  margin-left:8px
}

.channel-placeholder{
  display:grid;
  grid-template-columns:auto 1fr;
  width:100%;
  grid-row-gap:28px;
  row-gap:28px;
  grid-column-gap:1.5rem;
  -webkit-column-gap:1.5rem;
  column-gap:1.5rem
}

.channel-placeholder-name{
  width:80px;
  min-width:80px;
  max-width:80px;
  height:80px;
  min-height:80px;
  max-height:80px;
  border-radius:9px;
  background-color:#ddd
}

.channel-placeholder-content{
  display:flex;
  flex-direction:column;
  justify-content:space-evenly
}

.channel-placeholder-line{
  width:100%;
  height:1rem;
  min-height:1rem;
  max-height:1rem;
  border-radius:2px;
  background-color:#ddd
}

.channel-placeholder-line.half{
  width:50%
}

.actions-placeholder{
  margin:0;
  max-width:800px;
  width:100%
}

.actions-placeholder-line{
  width:100%;
  height:2rem;
  min-height:2rem;
  max-height:2rem;
  border-radius:2px;
  background-color:#ddd;
  margin:2rem 0
}

.actions-placeholder-line.even{
  width:90%
}

.skeleton-box{
  position:relative;
  overflow:hidden
}

.skeleton-box::after{
  position:absolute;
  top:0;
  right:0;
  bottom:0;
  left:0;
  -webkit-transform:translateX(-100%);
  transform:translateX(-100%);
  background-image:linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0));
  -webkit-animation:shimmer 3s infinite;
  animation:shimmer 3s infinite;
  content:""
}

@-webkit-keyframes shimmer{
  100%{
    -webkit-transform:translateX(100%);
    transform:translateX(100%)
  }
}

@keyframes shimmer{
  100%{
    -webkit-transform:translateX(100%);
    transform:translateX(100%)
  }
}

.customSpan{
  color:#0a3ab4;
  margin-left:1rem;
  cursor:pointer;
  font-size:1rem;
  line-height:1.5rem;
  font-weight:400
}

.color-picker{
  -webkit-appearance:none;
  height:20px;
  width:20px;
  border-radius:8px;
  border:none;
  background-color:transparent;
  overflow:hidden
}

.color-picker::-webkit-color-swatch-wrapper{
  padding:0
}

.color-picker::-webkit-color-swatch{
  border:none
}

.hidden-scrollbar::-webkit-scrollbar{
  display:none
}

/* purgecss end ignore */
/* purgecss start ignore */

.nlu-overview-table-contents td{
  border:none !important;
  padding:16px 8px !important;
  max-width:15rem !important
}

.nlu-overview-table-contents td:first-child{
  padding-left:16px !important
}

.nlu-overview-table-contents td p{
  margin:0
}

.nlu-overview-table-contents .utterance{
  font-family:"Open Sans";
  font-size:16px;
  color:#29302e;
  line-height:1.5
}

.nlu-overview-table-contents .utterance-date{
  font-family:"Open Sans";
  font-size:16px;
  color:#7a6769;
  line-height:1.5
}

.nlu-overview-table-contents .intent-percentage{
  font-family:"Open Sans";
  font-size:16px;
  line-height:1.5;
  color:#7a6769
}

.nlu-overview-table-contents .nlu-intent-hit{
  display:flex;
  flex-direction:row;
  justify-content:space-between
}

.intent-table-header{
  display:flex;
  justify-content:space-between
}

.intent-table-header .reload-icon:hover circle{
  fill:#ccdffa
}

.intent-table-header .reload-icon:active circle{
  fill:#98bdf7
}

.nlu-overview-table-titles th{
  font-family:"Open Sans";
  font-size:13px;
  font-weight:600;
  color:#7a6769;
  text-transform:uppercase;
  padding-left:8px !important
}

.nlu-overview-table-titles th:first-child{
  padding-left:16px !important
}

.main-title{
  font-family:"Open Sans";
  font-size:20px;
  font-weight:600;
  color:#29302e;
  margin-bottom:8px;
  line-height:1.33
}

.description-text{
  font-family:"Open Sans";
  font-size:16px;
  color:#29302e;
  line-height:1.5;
  margin-bottom:40px
}

.description-text.first{
  margin-bottom:16px
}

.edit-intent-button g g path{
  fill:silver
}

.edit-intent-button circle{
  fill:#fff
}

.edit-intent-button:hover circle{
  fill:#cbdffb
}

.edit-intent-button:hover g g path{
  fill:#0a3ab4
}

.modal{
  text-align:center
}

@media screen and (min-width: 768px){
  .modal:before{
    display:inline-block;
    vertical-align:middle;
    content:" ";
    height:100%
  }
}

.modal-dialog{
  display:inline-block;
  text-align:left;
  vertical-align:middle
}

.edit-utterance-modal{
  font-family:"Open Sans"
}

.edit-utterance-modal .modal-content{
  border-radius:0
}

.edit-utterance-modal--header{
  box-shadow:0 0 15px 0 rgba(0,0,0,.1),0 0 6px 0 rgba(0,0,0,.1);
  padding:1.43rem 2.86rem
}

.edit-utterance-modal--header h4{
  font-size:1.28rem;
  font-weight:600;
  line-height:1.33;
  color:#29302e
}

.edit-utterance-modal--body{
  padding:0 2.86rem;
  padding-bottom:2.86rem
}

.edit-utterance-modal--body p{
  font-size:1.14rem;
  line-height:1.5;
  color:#29302e;
  margin:1.7rem 0
}

.edit-utterance-modal--body .display-utterance{
  display:flex;
  flex-direction:row;
  margin-bottom:2.86rem
}

.edit-utterance-modal--body .display-utterance .intent-select{
  width:17.71rem
}

.edit-utterance-modal--body .display-utterance .intent-utterance-in-modal{
  width:32.57rem;
  border:1px solid #bbb;
  padding:1.3rem;
  border-radius:3px;
  height:4.1rem;
  font-size:16px
}

.edit-utterance-modal--body .display-utterance .nlu-overview-labels{
  font-weight:600;
  font-size:12px;
  margin:0;
  margin-bottom:.1rem
}

.intents-option{
  font-family:"Open Sans";
  font-size:16px;
  line-height:1.5;
  color:#29302e
}

.intents-option span{
  color:#7a6769;
  margin-left:8px
}

.modal-width{
  width:57.7rem
}

button.MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-popupIndicator:hover{
  background-color:transparent
}

.table-big-numbers{
  margin:0;
  font-size:26px;
  line-height:1.23;
  color:#716f6f
}

.table-bold{
  font-weight:600;
  margin:0
}

.nlu-training-state{
  width:100%;
  padding:24px;
  border-radius:4px;
  background-color:#f4f6ff
}

.nlu-training-state-top{
  display:grid;
  grid-template-columns:56px 1fr;
  grid-gap:16px;
  margin-bottom:16px
}

.nlu-training-state-top h5{
  font-size:16px;
  font-weight:600;
  color:#29302e;
  margin:0
}

.nlu-training-state-top h6{
  font-size:16px;
  font-weight:normal;
  color:#29302e;
  margin:0
}

.nlu-training-state-icon{
  width:56px;
  height:56px;
  display:flex;
  justify-content:center;
  align-items:center
}

.nlu-training-state-icon>svg{
  width:100%;
  height:100%
}

.nlu-training-state-icon>svg>g{
  fill:#32b777
}

.nlu-training-state-icon.warning svg{
  width:56px;
  height:56px
}

.nlu-training-state-icon.warning svg g{
  fill:transparent
}

.nlu-training-state-actions{
  display:flex;
  justify-content:flex-end
}

.nlu-training-state-actions>*:first-child{
  margin-right:8px
}

.nlu-training-state-actions>button:last-child{
  min-width:115px
}

.nlu-training-state-actions.loading>button:last-child>span{
  left:50%;
  -webkit-transform:translate(-50%, 0);
  transform:translate(-50%, 0)
}

.nlu-overview-status{
  margin-bottom:40px
}

/* purgecss end ignore */
/* purgecss start ignore */

#previewContainer{
  width:auto !important;
  height:calc(100vh - 5rem) !important
}

#previewContainer #pypestream-wrapper{
  width:auto !important;
  height:calc(100vh - 5rem) !important;
  position:absolute;
  left:0;
  margin-left:-0.79rem;
  margin-right:-0.19rem
}

/* purgecss end ignore */
