.nlu-overview-table-contents {
  td {
    border: none !important;
    padding: 16px 8px !important;
    max-width: 15rem !important;

    &:first-child {
      padding-left: 16px !important;
    }
  }

  td p {
    margin: 0;
  }

  .utterance {
    font-family: "Open Sans";
    font-size: 16px;
    color: #29302e;
    line-height: 1.5;
  }

  .utterance-date {
    font-family: "Open Sans";
    font-size: 16px;
    color: #7a6769;
    line-height: 1.5;
  }

  .intent-percentage {
    font-family: "Open Sans";
    font-size: 16px;
    line-height: 1.5;
    color: #7a6769;
  }

  .nlu-intent-hit {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.intent-table-header {
  display: flex;
  justify-content: space-between;
  .reload-icon {
    &:hover {
      circle {
        fill: #ccdffa;
      }
    }

    &:active {
      circle {
        fill: #98bdf7;
      }
    }
  }

  .reload-waiting {
  }
}

.nlu-overview-table-titles {
  th {
    font-family: "Open Sans";
    font-size: 13px;
    font-weight: 600;
    color: #7a6769;
    text-transform: uppercase;
    padding-left: 8px !important;

    &:first-child {
      padding-left: 16px !important;
    }
  }
}

.main-title {
  font-family: "Open Sans";
  font-size: 20px;
  font-weight: 600;
  color: #29302e;
  margin-bottom: 8px;
  line-height: 1.33;
}

.description-text {
  font-family: 'Open Sans';
  font-size: 16px;
  color: #29302e;
  line-height: 1.5;
  margin-bottom: 40px;

  &.first {
      margin-bottom: 16px;
  }
}

.edit-intent-button {
  g g path {
    fill: #c0c0c0;
  }

  circle {
    fill: #fff;
  }

  &:hover {
    circle {
      fill: #cbdffb;
    }

    g g path {
      fill: #0a3ab4;
    }
  }
}

.modal {
  text-align: center;
}

@media screen and (min-width: 768px) {
  .modal:before {
    display: inline-block;
    vertical-align: middle;
    content: " ";
    height: 100%;
  }
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}

.edit-utterance-modal {
  font-family: "Open Sans";

  .modal-content {
    border-radius: 0;
  }

  &--header {
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1), 0 0 6px 0 rgba(0, 0, 0, 0.1);
    padding: 1.43rem 2.86rem;

    h4 {
      font-size: 1.28rem;
      font-weight: 600;
      line-height: 1.33;
      color: #29302e;
    }
  }

  &--body {
    padding: 0 2.86rem;
    padding-bottom: 2.86rem;

    p {
      font-size: 1.14rem;
      line-height: 1.5;
      color: #29302e;
      margin: 1.7rem 0;
    }

    .display-utterance {
      display: flex;
      flex-direction: row;
      margin-bottom: 2.86rem;

      .intent-select {
        width: 17.71rem;
      }

      .intent-utterance-in-modal {
        width: 32.57rem;
        border: 1px solid #bbbbbb;
        padding: 1.3rem;
        border-radius: 3px;
        height: 4.1rem;
        font-size: 16px;
      }

      .nlu-overview-labels {
        font-weight: 600;
        font-size: 12px;
        margin: 0;
        margin-bottom: 0.1rem;
      }

      .intent-utterance-wrapper {
      }
    }
  }
}

.intents-option {
  font-family: "Open Sans";
  font-size: 16px;
  line-height: 1.5;
  color: #29302e;

  span {
    color: #7a6769;
    margin-left: 8px;
  }
}

.modal-width {
  width: 57.7rem;
}

button.MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-popupIndicator {
  &:hover {
    background-color: transparent;
  }
}

.table-big-numbers {
  margin: 0;
  font-size: 26px;
  line-height: 1.23;
  color: #716f6f;
}

.table-bold {
  font-weight: 600;
  margin: 0;
}

.nlu-training-state {
  width: 100%;
  padding: 24px;
  border-radius: 4px;
  background-color: #f4f6ff;

  &-top {
    display: grid;
    grid-template-columns: 56px 1fr;
    grid-gap: 16px;
    margin-bottom: 16px;

    h5 {
      font-size: 16px;
      font-weight: 600;
      color: #29302e;
      margin: 0;
    }

    h6 {
      font-size: 16px;
      font-weight: normal;
      color: #29302e;
      margin: 0;
    }
  }

  &-icon {
    width: 56px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;

    & > svg {
      width: 100%;
      height: 100%;

      & > g {
        fill: #32b777;
      }
    }

    &.warning {
      svg {
        width: 56px;
        height: 56px;
      }

      svg g {
        fill: transparent;
      }
    }
  }

  &-actions {
    display: flex;
    justify-content: flex-end;

    & > *:first-child {
      margin-right: 8px;
    }

    & > button:last-child {
      min-width: 115px;
    }

    &.loading {
      & > button:last-child > span {
        left: 50%;
        transform: translate(-50%, 0);
      }
    }
  }
}

.nlu-overview-status {
  margin-bottom: 40px;
}
